<template>
  <div class="componentSty">
    <div class="componentSty_content">
      <div class="componentSty_content_title">
        <el-form inline size="small">
          <el-form-item label="菜单名称">
            <el-input
              placeholder="请输入关键词"
              v-model="selectinfo.name"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getmenulist">查询</el-button>
            <el-button @click="cleartiaojian"> 清空 </el-button>
          </el-form-item>
          <el-form-item style="float: right">
            <span
              style="font-size: 25px"
              class="iconfont icon-xiangxiajiantou fonints"
              @click="apendfun"
            ></span>
            <span class="fonints" style="font-size: 30px" @click="addmsg"
              >+</span
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="componentSty_content_table">
        <el-table
          :data="arrlist"
          row-key="menuId"
          :expand-row-keys="expendlist"
          :cell-style="rowclassSty"
          :tree-props="{ children: 'children' }"
        >
          <!-- <el-table-column prop="orderNum" label="排序"> </el-table-column> -->
          <el-table-column
            label="序号"
            type="index"
            width="50px"
          ></el-table-column>
          <el-table-column prop="name" label="菜单名称"> </el-table-column>
          <el-table-column prop="url" label="请求地址"> </el-table-column>
          <el-table-column label="类型">
            <template v-slot="res">
              <span v-if="res.row.type == 0">模块</span>
              <span v-if="res.row.type == 1">页面</span>
              <span v-if="res.row.type == 2">按钮</span>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template v-slot="res">
              <span
                class="iconfont icon-xiugai"
                @click="itemparticulars(res.row)"
              ></span>
              <span
                class="iconfont icon-shanchu"
                @click="deleteItem(res.row)"
              ></span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-dialog
        :visible.sync="addrole"
        @changeState="sendMsg"
        center
        width="30%"
        :title="isedit ? '编辑菜单' : '添加菜单'"
      >
        <el-form size="medium" label-width="80px">
          <el-row v-if="!isedit">
            <el-form-item label="菜单类型">
              <el-radio-group v-model="radio">
                <el-radio :label="0">模块</el-radio>
                <el-radio :label="1">页面</el-radio>
                <el-radio :label="2">按钮</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item :label="radio == 2 ? '上级页面' : '上级模块'">
              <el-select
                style="width: 100%"
                v-model="infomsg.parentId"
                filterable
                remote
                :remote-method="getuplist"
                placeholder="请输入搜索关键字"
              >
                <el-option
                  v-for="item in uplist"
                  :key="item.menuId"
                  :label="item.name"
                  :value="item.menuId"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item
              :label="
                radio == 0 ? '模块名称' : radio == '1' ? '页面名称' : '按钮名称'
              "
            >
              <el-input type="text" v-model="infomsg.name" maxlength="10" />
            </el-form-item>
          </el-row>
          <el-row v-show="radio == 0">
            <el-form-item label="icon图标">
              <el-input type="text" v-model="infomsg.icon" />
            </el-form-item>
          </el-row>
          <el-row v-show="radio == 0">
            <el-form-item label="显示排序">
              <el-input type="text" v-model="infomsg.orderNum" />
            </el-form-item>
          </el-row>
          <el-row v-show="radio == 1">
            <el-form-item label="请求地址">
              <el-input type="text" v-model="infomsg.url" />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item
              v-if="!isedit"
              :label="
                radio == 0 ? '模块状态' : radio == '1' ? '页面状态' : '按钮状态'
              "
            >
              <el-radio-group v-model="infomsg.status">
                <el-radio :label="0">正常</el-radio>
                <el-radio :label="1">停用</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button
            class="buttons"
            size="small"
            type="primary"
            @click="sendMsg"
            >确 定</el-button
          >
          <el-button size="small" @click="addrole = false">取 消</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import mTitle from "@/components/mTitle";
import mDialog from "@/components/mDialog";
import mSelect from "@/components/mSelect";
import { mapMutations, mapState } from "vuex";
export default {
  inject: ["reload", "alertDialog"],
  data() {
    return {
      listitem: [{ name: "角色权限" }, { name: "菜单管理" }],
      selectinfo: {
        name: "",
      },
      infomsg: {
        orderNum: "", //显示排序
        parentId: "", //上级模块
        name: "", //模块名称
        url: "", //请求地址
        menuId: "",
        type: "",
        status: "",
        icon: "",
      },
      uplist: [],
      addrole: false,
      expandall: true,
      radio: "0",
      expendlist: [],
      arrlist: [],
      isedit: false,
    };
  },
  computed: {
    ...mapState(["conditionsList"]),
  },
  components: {
    mTitle,
    mDialog,
    mSelect,
  },
  watch: {
    radio() {
      this.uplist = [];
    },
  },
  created() {
    this.conditionsList[this.$route.path] ||
      this.createConditions({
        key: this.$route.path,
        val: {
          name: "",
        },
      });
    this.selectinfo = this.conditionsList[this.$route.path] || {
      name: "",
    };
    this.getmenulist();
  },
  methods: {
    ...mapMutations(["createConditions"]),
    difuifun(arr1, arr2) {
      if (!arr1.length || !arr2.length) return "";
      arr1.forEach((item) => {
        arr2.forEach((item2) => {
          if (item.menuId === item2.parentId) {
            item.children.push(item2);
          }
        });
      });
    },
    async getmenulist() {
      const { data } = await this.$http(
        "api1",
        "/sys/menu/list",
        "get",
        this.selectinfo
      );
      if (!data) return;
      let obj = data.reduce(
        (result, item) => {
          if (item.type == 0 && !item.parentName) {
            item.children = [];
            result[0].push(item);
          } else if (item.parentName && item.type == 0) {
            item.children = [];
            result[1].push(item);
          } else if (item.type == 1) {
            item.children = [];
            result[2].push(item);
          } else {
            result[3].push(item);
          }
          return result;
        },
        { 0: [], 1: [], 2: [], 3: [] }
      );
      this.difuifun(obj[0], obj[1]);
      this.difuifun(obj[1], obj[2]);
      this.difuifun(obj[2], obj[3]);
      console.log(obj[0]);
      this.arrlist = obj[0];
    },
    cleartiaojian() {
      this.selectinfo.name = "";
      this.getmenulist();
    },
    async getuplist(val) {
      let path = "/sys/menu/listModule";
      let obj = {
        moduleName: val,
      };
      if (this.radio == 2) {
        path = "/sys/menu/listPage";
        obj = {
          pageName: val,
        };
      }
      const { data } = await this.$http("api1", path, "get", obj);
      if (!data) return;
      if (!data.length) return (this.uplist = [{}]);
      this.uplist = data;
    },
    itemparticulars(res) {
      this.isedit = true;
      this.addrole = true;
      this.radio = res.type;
      for (var i in this.infomsg) {
        this.infomsg[i] = res[i];
      }
    },
    addmsg() {
      this.isedit = false;
      this.addrole = true;
      for (var i in this.infomsg) {
        this.infomsg[i] = "";
      }
    },
    deleteItem(res) {
      this.$messageBox
        .confirm("确认删除该菜单, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(async () => {
          const { data } = await this.$http(
            "api1",
            `/sys/menu/delete/${res.menuId}`,
            "post"
          );
          if (!data || data.msg != "success")
            return this.$message.error("删除失败");
          this.$message.success("删除成功");
          this.getmenulist();
        });
    },
    apendfun() {
      if (this.expendlist.length) {
        return;
      }
      this.expendlist = this.arrlist.reduce((result, item) => {
        result.push(item.menuId);
        if (item.children.length) {
          item.children.forEach((item1) => {
            result.push(item1.menuId);
            if (item1.children.length) {
              item1.children.forEach((item2) => {
                result.push(item2.menuId);
              });
            }
          });
        }
        return result;
      }, []);
    },
    async sendMsg() {
      let path = "/sys/menu/update";
      if (!this.isedit) {
        this.infomsg.type = Number(this.radio);
        path = "/sys/menu/save";
      }
      let isok = false;
      switch (this.radio) {
        case "0":
          isok = !this.infomsg.name || !this.infomsg.orderNum;
          break;
        case "1":
          isok =
            !this.infomsg.name || !this.infomsg.parentId || !this.infomsg.url;
          break;
        case "2":
          isok = !this.infomsg.name || !this.infomsg.parentId;
          break;
      }
      if (isok) return this.$message.error("必填项不能为空");
      const { data } = await this.$http("api1", path, "post", this.infomsg);
      if (!data || data.msg != "success") return this.$message.error("失败");
      this.$message.success("成功");
      this.addrole = false;
    },
    rowclassSty({ row, column }) {
      if (column.label == "排序" && row.type == 0) {
        return "text-align: left!important;box-sizing: border-box;padding-left: 5%!important;color:#2B56BF";
      }
      if (row.type == 0) {
        return "color:#2B56BF";
      } else if (row.type == 1) {
        return "color:#FF5926";
      } else if (row.type == 2) {
        return "color:#13D168";
      }
    },
  },
};
</script>
<style lang="scss" src="@/assets/components.scss" scoped></style>
